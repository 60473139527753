import * as React from "react";
import ContactForm from "../components/compositions/contact-form";

export default function IndexPage({ data }) {
  return (
    <>
      <h1>Contact Information</h1>
      <ContactForm />
      <br />
      <b>
        US Headquarters
        <br />
      </b>
      United Laboratories, Inc.
      <br />
      320 37th Avenue
      <br />
      St. Charles, IL&nbsp; 60174
      <br />
      <a
        href="http://maps.google.com/maps?q=320+37th+Avenue+St.+Charles,+IL+60174&amp;spn=0.030338,0.076321&amp;iwloc=A&amp;hl=en"
        target="_blank"
        rel="noreferrer"
      >
        Click Here
      </a>
      &nbsp;for Map &amp; Driving Directions
      <p>Main Toll-Free:(800) 323-2594</p>
    </>
  );
}
