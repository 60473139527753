import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createEmail } from "../../graphql/mutations";
import { Form, Button, Alert } from "react-bootstrap";

const ContactForm = () => {
  const [formEnabled, setFormEnabled] = React.useState(true);
  const [alertState, setAlertState] = React.useState("hidden");
  const [alertMsg, setAlertMsg] = React.useState();
  const [emailFromName, setEmailFromName] = React.useState();
  const [emailReplyToAddress, setEmailReplyToAddress] = React.useState();
  const [emailMsg, setEmailMsg] = React.useState();
  const submitHandler = e => {
    e.preventDefault();
    setFormEnabled(false);
    sendEmail();
  };
  async function sendEmail() {
    try {
      const createEmailParams = {
        template: "mdsupplyContactEmailTemplate",
        replyTo: emailReplyToAddress,
        senderName: emailFromName,
        msg: emailMsg
      };
      await API.graphql(graphqlOperation(createEmail, createEmailParams));
      setAlertMsg(
        "Thank you! Your message has been sent to our customer support staff."
      );
      setAlertState("success");
    } catch (error) {
      setAlertMsg(
        "Uh, oh. An error has occured. Please try again later. The problem has been logged and we will work on resolving it. " +
          error.message
      );
      setAlertState("info");
    }
  }
  return (
    <Form onSubmit={submitHandler}>
      <Alert variant={alertState}>
        <p>{alertMsg && alertMsg}</p>
      </Alert>
      <Form.Group className="mb-3" controlId="contactForm.ControlNameInput">
        <b>Send us a message: </b>
        <br />
        <Form.Label>Your Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Your Name"
          onChange={e => {
            setEmailFromName(e.target.value);
          }}
          disabled={!formEnabled}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="contactForm.ControlEmailInput">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          onChange={e => {
            setEmailReplyToAddress(e.target.value);
          }}
          disabled={!formEnabled}
          required
        />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group
        className="mb-3"
        controlId="contactForm.ControlMessageTextarea"
      >
        <Form.Label>Your Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={e => {
            setEmailMsg(e.target.value);
          }}
          disabled={!formEnabled}
          required
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={!formEnabled}>
        Send
      </Button>
    </Form>
  );
};
export default ContactForm;
